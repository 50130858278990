.shop {
    .container {
        height: 100vh;
        width: 100%;
        background: url("../../public/images/ch-logo-1.jpeg") no-repeat 70% / cover;
        filter: brightness(60%);
        position: relative;

        .presentation {
            position: fixed;
            top: 50%;
            left: 60%;
            transform: translate(-50%, -50%);
            background-color: rgba(245, 245, 245, 0.196);
            border: 1px solid transparent;
            padding: 2rem;
            //border-radius: 1rem;
            width: 30%;

            animation: floatAnimation 4s infinite;

            @keyframes floatAnimation {
                0% {
                    transform: translateY(0);
                }
                50% {
                    transform: translateY(-10px);
                }
                100% {
                    transform: translateY(0);
                }
            }

            .text {
                font-size: 2rem;
                font-weight: 100;
                padding-bottom: 1rem;
            }
            .pub {
                font-size: 3rem;
                font-weight: 100;

                span {
                    transition: all 0.4s ease-in-out;
                    cursor: pointer;
                }
                span:hover {
                    font-weight: 300;

                    color: rgb(213, 171, 171);
                }
            }
            .pub,
            .text,
            span {
                color: white;
                text-shadow: 1px 1px 1px black;
            }
        }
    }
}

@media screen and (max-width: 582px) {
    .shop {
        .container {
            background: url("../../public/images/ch-logo-1.jpeg") no-repeat 16% / cover;
            filter: brightness(45%);

            .presentation {
                top: 40%;
                left: 12%;
                transform: translate(-50%, -50%);
                width: 60%;
                margin: 0 auto;
                background-color: rgba(245, 245, 245, 0.388);

                .text {
                    font-size: larger;
                    font-weight: 100;
                    padding-bottom: 1rem;
                }
                .pub {
                    font-size: 2rem;
                    font-weight: 300;
    
                    span {
                        transition: all 0.4s ease-in-out;
                        cursor: pointer;
                    }
                    span:hover {
                        font-weight: 300;
    
                        color: rgb(213, 171, 171);
                    }
                }
                .pub,
                .text,
                span {
                    color: white;
                    text-shadow: 1px 4px 4px black;
                }
            }
        }
    }
}
